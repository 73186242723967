<template>
    <el-dialog
        :before-close="closeDialog"
        :show-close="false"
        :visible.sync="dialogVisible"
        center
        width="7.9in">
        <span slot="title" class="title">打印预览</span>
        <div class="dialog-container">
            <div id="printTest">
                <div class="apply-title">会议付款确认单</div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">项目编号：</span>
                    <span class="approval-detail-content">{{ detail.project.pro_no.join(',') }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">所属学会：</span>
                    <span class="approval-detail-content">{{ detail.society }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议名称', detail.project.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.project.name }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('会议日期', detail.project.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.time }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">{{ $getTitleByType('大会主席', detail.project.cid) }}：</span>
                    <span class="approval-detail-content">{{ detail.project.chairman }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">委托项目：</span>
                    <span class="approval-detail-content">{{ detail.pay_item }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">付款金额：</span>
                    <span class="approval-detail-content">{{ parseFloat(Number(detail.pay_amount).toFixed(6))
                        }}万元</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">收款单位：</span>
                    <span class="approval-detail-content">{{ detail.payee }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">收款单位开户行：</span>
                    <span class="approval-detail-content">{{ detail.bank_name }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip">收款单位账号：</span>
                    <span class="approval-detail-content">{{ detail.bank_no }}</span>
                </div>
                <div class="approval-detail-title">
                    <span class="approval-detail-tip red">备注：</span>
                    <span class="approval-detail-content red">{{ detail.remark }}</span>
                </div>
                <div class="approval-detail-container">
                    <div class="approval-detail-title">
                        <!--                        <span class="approval-detail-tip">签名：</span>-->
                        <div class="approval-detail-content">
                            <el-image :src="detail.sign" style="width: 100%; height: auto"></el-image>
                        </div>
                    </div>
                </div>
                <div class="approval-list-container">
                    <div class="approval-list-tip">审批流程</div>
                    <div class="approval-list">
                        <!--          付款：审核状态 0已提交申请，等待签字  1审批完成，等待发起人确认  2已签字，选择付款交接文件  3已驳回  4二次提交，审批中  5撤销  6已完成-->

                        <div class="name-container active">
                            <span>{{ detail.true_name | sub_name }}<i class="el-icon-success"></i></span>
                            <div class="info-container">
                                <div class="info-top">
                                    <div class="info-content">{{ detail.true_name }}</div>
                                    <div class="info-time">{{ detail.add_time | formatTime }}</div>
                                </div>
                                <div class="info-desc">发起项目付款确认单签字申请</div>
                            </div>
                        </div>

                        <div class="name-container active">
                            <span>签字<i :class="detail.sign ? 'el-icon-success' : 'el-icon-remove'"></i></span>
                            <div class="info-container">
                                <div class="info-top">
                                    <div class="info-content">申请人</div>
                                    <div v-if="detail.allSp[0].sp_time " class="info-time">
                                        {{ detail.allSp[0].sp_time | formatTime }}
                                    </div>
                                </div>
                                <div class="info-desc">
                                    {{ detail.sign ? "申请人已签字" : "签字中" }}
                                </div>
                            </div>
                        </div>

                        <div v-if="detail.sign" class="name-container active">

            <span>
              {{ detail.true_name | sub_name }}
              <i
                  :class="
                  detail.fukuan_jiaojie.length > 0
                    ? 'el-icon-success'
                    : 'el-icon-remove'
                "
              ></i>
            </span>
                            <div class="info-container">
                                <div class="info-top">
                                    <div class="info-content">{{ detail.true_name }}</div>
                                    <div v-if="detail.allSp[1].sp_time " class="info-time">
                                        {{ detail.allSp[1].sp_time | formatTime }}
                                    </div>
                                    <!--<div class="info-time">{{ detail.add_time | formatTime }}</div>-->
                                </div>
                                <div v-if="detail.fukuan_jiaojie.length > 0" class="info-desc">
                                    <div v-for="(item,index) in detail.subsidiary.slice(0,1)" :key="index">
                                        {{ detail.true_name }}付款交接文件：{{ item.fukuan_jiaojie.split(',') | fukuan_jiaojie
                                        }} <br>发票总金额：{{ parseFloat(Number(detail.all_amount).toFixed(6)) }}万元<br>
                                        发票金额：{{ parseFloat(Number(item.all_amount).toFixed(6)) }}万元 备注：{{ item.notes }}
                                    </div>
                                </div>
                                <div v-else class="info-desc">
                                    <div v-if="member_type != 1">{{ detail.true_name }}选择付款交接文件中</div>
                                    <div v-if="member_type == 1">
                                        <!--<div class="info-desc">-->
                                        <!--<div v-if="member_type != 1">{{ detail.true_name }}选择付款交接文件中</div>-->
                                        <!--<div >-->
                                        <div class="input-item">
                                            <div class="input-item-title">付款交接文件</div>
                                            <div class="flex-center">
                                                <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate"
                                                             @change="handleCheckAllChange">全选
                                                </el-checkbox>
                                                <el-checkbox-group v-model="fukuan_jiaojie" class="margin-left"
                                                                   @change="handleCheckedChange">
                                                    <el-checkbox label="1">下游专票</el-checkbox>
                                                    <el-checkbox label="2">下游协议</el-checkbox>
                                                    <el-checkbox label="3">项目明细</el-checkbox>
                                                    <el-checkbox label="4">支持性文件</el-checkbox>
                                                    <el-checkbox label="5">支出凭单</el-checkbox>
                                                </el-checkbox-group>
                                                <div class="margin-left">发票金额：</div>
                                                <el-input v-model="all_amount" placeholder="请填写发票总金额" style="width: 300px"
                                                          type="number" @mousewheel.native.prevent></el-input>
                                                <div>万元</div>
                                            </div>
                                            <div class="flex-center">
                                                <div class="margin-left flex-shrink">备注：</div>
                                                <el-input v-model="notes" placeholder="请填写备注"></el-input>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="detail.fukuan_jiaojie.length > 0">
                            <div v-for="(item,index) in detail.shenpi.slice(0,3)"
                                 :key="item.id">
                                <div
                                    v-if="index === 0 ? true : detail.shenpi[index-1].state === 1"
                                    class="name-container active"
                                >
              <span
              >{{
                      item.true_name | sub_name
                  }}
                <i
                    :class="
                    item.state === 0 || item.state === 3
                      ? 'el-icon-remove'
                      : item.state === 1
                      ? 'el-icon-success'
                      : 'el-icon-error'
                  "
                ></i>
              </span>
                                    <div class="info-container">
                                        <div class="info-top">
                                            <div class="info-content">
                                                {{ item.true_name
                                                }}<i v-if="item.state != 0"
                                            >({{ item.state | sp_state }})</i
                                            >
                                            </div>
                                            <div v-if="item.sp_time" class="info-time">
                                                {{ item.sp_time | formatTime }}
                                            </div>
                                        </div>
                                        <div v-if="item.state === 0" class="info-desc">
                                            {{ iself(item) ? "我（" : "" }}{{ item.state | sp_state
                                            }}{{ iself(item) ? "）" : "" }}
                                        </div>
                                        <div v-if="item.state !== 0" class="info-desc">
                                            {{ item.sp_remark }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="detail.shenpi[2].state == 1"
                        >
                            <div
                                v-for="item in detail.shenpi.slice(3,4)"
                                :key="item.id"
                                class="name-container active"
                            >
            <span
            >{{
                    item.true_name | sub_name
                }}
              <i
                  :class="
                  item.state === 0
                    ? 'el-icon-remove'
                    : item.state === 1
                    ? 'el-icon-success'
                    : 'el-icon-error'
                "
              ></i>
            </span>
                                <div class="info-container">
                                    <div class="info-top">
                                        <div class="info-content">
                                            {{ item.true_name
                                            }}
                                        </div>
                                        <div v-if="item.sp_time" class="info-time">
                                            {{ item.sp_time | formatTime }}
                                        </div>
                                    </div>
                                    <div v-if="item.state == 1" class="info-desc">
                                        金额：{{ parseFloat(Number(detail.payment_amount).toFixed(6)) }}万元
                                        时间：{{ detail.payment_date }}
                                    </div>
                                    <div v-else class="info-desc">
                                        <!--等待财务对接人确认-->
                                        付款中
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div v-if="detail.shenpi[3].state == 1">
                            <div
                                v-for="(item,index) in detail.shenpi.slice(4)"
                                :key="item.id">
                                <div v-if="detail.shenpi[3+index].state == 1 && index%2==0"
                                    class="name-container">
            <span>
              {{
                    item.true_name | sub_name
                }}
              <i :class="
                  item.state == 1 ? 'el-icon-success' : 'el-icon-remove'
                "
              ></i>
            </span>
                                    <div class="info-container">
                                        <div class="info-top">
                                            <div class="info-content">
                                                {{ item.true_name }}
                                            </div>
                                            <div v-if="item.sp_time" class="info-time">
                                                {{ item.sp_time | formatTime }}
                                            </div>
                                        </div>
                                        <div v-if="detail.fukuan_state" class="info-desc">
                                            {{ item.state == 1 ? "确认知晓" : "确认中" }}
                                        </div>
                                        <div v-else>
                                            <div v-if="item.state === 1" class="info-desc">
                                                发票总金额：{{ parseFloat(Number(detail.all_amount).toFixed(6)) }}万元<br>
                                                付款交接文件：{{ item.fukuan_jiaojie.split(',') | fukuan_jiaojie
                                                }} <br>发票金额：{{ parseFloat(Number(item.all_amount).toFixed(6)) }}万元
                                                备注：{{ item.notes }}
                                            </div>
                                            <div v-else class="info-desc">
                                                <div v-if="member_type != 1">{{ item.true_name }}付款交接文件补全中</div>
                                                <div v-if="member_type == 1">
                                                    <!--<div class="info-desc">-->
                                                    <!--<div v-if="member_type != 1">{{ detail.true_name }}选择付款交接文件中</div>-->
                                                    <!--<div >-->
                                                    <div class="input-item">
                                                        <div class="input-item-title">付款交接文件</div>
                                                        <div class="flex-center">
                                                            <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate"
                                                                         @change="handleCheckBCAllChange">全选
                                                            </el-checkbox>
                                                            <el-checkbox-group v-model="fukuan_jiaojie" class="margin-left"
                                                                               @change="handleCheckedChange">
                                                                <el-checkbox :disabled="detail.fukuan_jiaojie.indexOf('1') !== -1 "
                                                                             label="1">
                                                                    下游专票
                                                                </el-checkbox>
                                                                <el-checkbox :disabled="detail.fukuan_jiaojie.indexOf('2') !== -1 "
                                                                             label="2">
                                                                    下游协议
                                                                </el-checkbox>
                                                                <el-checkbox :disabled="detail.fukuan_jiaojie.indexOf('3') !== -1 "
                                                                             label="3">
                                                                    项目明细
                                                                </el-checkbox>
                                                                <el-checkbox :disabled="detail.fukuan_jiaojie.indexOf('4') !== -1 "
                                                                             label="4">
                                                                    支持性文件
                                                                </el-checkbox>
                                                                <el-checkbox :disabled="detail.fukuan_jiaojie.indexOf('5') !== -1 "
                                                                             label="5">
                                                                    支出凭单
                                                                </el-checkbox>
                                                            </el-checkbox-group>
                                                            <div class="margin-left">发票金额：</div>
                                                            <el-input v-model="all_amount" placeholder="请填写发票总金额"
                                                                      style="width: 300px"
                                                                      type="number"
                                                                      @mousewheel.native.prevent></el-input>
                                                            <div>万元</div>
                                                        </div>
                                                        <div class="flex-center">
                                                            <div class="margin-left flex-shrink">备注：</div>
                                                            <el-input v-model="notes" placeholder="请填写备注"></el-input>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="detail.shenpi[3+index].state == 1 && index%2==1"
                                    class="name-container active"
                                >
            <span
            >{{
                    item.true_name | sub_name
                }}
              <i
                  :class="
                  item.state === 0
                    ? 'el-icon-remove'
                    : item.state === 1
                    ? 'el-icon-success'
                    : 'el-icon-error'
                "
              ></i>
            </span>
                                    <div class="info-container">
                                        <div class="info-top">
                                            <div class="info-content">
                                                {{ item.true_name
                                                }}
                                            </div>
                                            <div v-if="item.sp_time" class="info-time">
                                                {{ item.sp_time | formatTime }}
                                            </div>
                                        </div>
                                        <div v-if="detail.state == 7" class="info-desc">
                                            {{ item.state === 1 ? '付款资料补充已确认' : '付款资料补充存档中' }}
                                        </div>
                                        <div v-else class="info-desc">
                                            <!--等待财务对接人确认-->
                                            {{ detail.shenpi.length == (3 + index) ? '付款资料补充已存档' : '付款资料补充已确认' }}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog">取消</el-button>
            <el-button v-print="'#printTest'" class="printing" type="primary">打印</el-button>
      </span>
    </el-dialog>
</template>

<script>
import {sub_name, ZHDate} from "../../../tools";

export default {
    name: "pay-print",
    props: {
        dialogVisible: {
            type: Boolean,
            default: false
        },
        detail: { //详情
            type: Object,
        },
    },
    methods: {
        iself: function (item) {
            return item.user_id === this.$store.state.user.mid;
        },
        closeDialog() {
            this.$emit('watchState', false)
        }
    },
    filters: {
        fukuan_jiaojie(type) {
            let arr = ["下游专票", "下游协议", "项目明细", "支持性文件", "支出凭单"];
            let str = "";
            type.map((item) => {
                str = str + " " + arr[Number(item) - 1];
            });
            return str;
        },
        jiesuan_jiaojie(type) {
            let arr = ["会议结算单", "结算单文件"];
            let str = "";
            type.map((item) => {
                str = str + " " + arr[Number(item) - 1];
            });
            return str;
        },
        state(type) {
            switch (Number(type)) {
                case 0:
                    return "等待签字确认";
                case 1:
                    return "发起人确认中";
                case 2:
                    return "已签字，发起人选择付款交接文件中";
                case 3:
                    return "已驳回";
                case 4:
                    return "审批中";
                case 5:
                    return "已撤销";
                case 7:
                    return "付款资料补充存档中";
                case 6:
                    return "已完成";
            }
        },
        sp_state(type) {
            switch (Number(type)) {
                case 0:
                    return "审批中";
                case 3:
                    return "未审批";
                case 1:
                    return "已通过";
                default:
                    return "已驳回";
            }
        },
        formatTime(time) {
            return ZHDate(new Date(time * 1000), 6)
        },
        sub_name(str) {
            return sub_name(str)
        },
    },
}
</script>

<style lang="scss" scoped>
.title {
    font-size: 16px;
    color: #333333;
    text-align: center;
    font-weight: bold;
}

.dialog-container {
    height: 600px;
    overflow-y: scroll;
    border: 1px solid #e1e1e1;

    &::-webkit-scrollbar {
        display: none;
    }
}

#printTest {
    background: #FFFFFF;
    padding: 20px;
    border: 1px solid #e1e1e1;
}

.apply-title {
    font-size: 22px;
    color: #333333;
    text-align: center;
    font-weight: bold;
    margin: 20px 0 50px;
}

.approval-detail-title {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    color: #999999;
    width: 100%;
    padding: 10px 0;

    .approval-detail-tip {
        flex: 1;
        flex-shrink: 0;

        &.red {
            color: red;
        }
    }

    .approval-detail-tip-length {
        flex-shrink: 0;

        &.red {
            color: red;
        }
    }

    .approval-detail-content {
        flex: 4;
        flex-shrink: 1;
        color: #333333;

        &.red {
            color: red;
        }
    }
}
.approval-list-container {
    display: flex;
    flex-direction: column;
    padding: 20px 30px;

    .approval-list {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        .name-container {
            position: relative;
            display: flex;
            padding-bottom: 50px;
            flex-direction: row;

            &.active {
                &:before {
                    position: absolute;
                    background: #d3d4d6;
                    width: 1px;
                    top: 0px;
                    bottom: 0px;
                    left: 28px;
                    content: "";
                }
            }

            .info-container {
                flex-grow: 1;
                margin-left: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .info-top {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                    .info-content {
                        font-size: 20px;
                        color: #333333;

                        i {
                            font-size: 14px;
                        }
                    }

                    .info-time {
                        font-size: 16px;
                        color: #999999;
                    }
                }

                .info-desc {
                    margin-top: 2px;
                    font-size: 16px;
                    color: #999999;
                }

                .copy-members {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin-top: 20px;

                    .copy-members-name {
                        margin-right: 10px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                }
            }

            span {
                display: flex;
                align-items: center;
                justify-content: space-around;
                width: 56px;
                height: 56px;
                border-radius: 56px;
                border: 1px solid #3b77e7;
                text-align: center;
                /*line-height: 24px;*/
                font-size: 16px;
                color: #ffffff;
                background-color: #3b77e7;
                position: relative;

                i {
                    position: absolute;
                    bottom: -5px;
                    right: -5px;
                    font-size: 18px;
                    width: 18px;
                    height: 18px;
                    overflow: hidden;

                    &.el-icon-remove {
                        color: #ee9900;
                    }

                    &.el-icon-success {
                        color: #13ce66;
                    }

                    &.el-icon-error {
                        color: #ff0000;
                    }
                }
            }
        }
    }

    .approval-list-tip {
        font-size: 18px;
        color: #333333;
        flex-shrink: 1;
        margin-top: 12px;
    }
}
</style>